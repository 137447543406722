import React from 'react';
import shallow from "zustand/shallow";
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { SimpleTextField } from 'components/FormTextField';
import ClientSelect from 'components/ClientSelect';
import useEditorStore from './editorStore';
import AddButton from './AddButton';
import { TemplateValidationSchema } from './constants';
import { useLoading, useSnackbar } from 'contexts';
import { saveSEPTemplate } from 'services/sepTemplates';
import { CheckboxOptions } from 'components/FormSelect';

function TemplateDetails(props) {
    const ref = React.useRef();

    const location = useLocation();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const [save, setSave] = React.useState(false);
    const [totalTime, setTotalTime] = React.useState(0);
    const [totalTimeError, setTotalTimeError] = React.useState('');

    const [validate, setValidate, template, updateTemplate, creator, errors,
        setTemplateError, getData, setTemplate, setCreator
    ] = useEditorStore(s => [
        s.validate, s.setValidate, s.template, s.updateTemplate, s.creator,
        s.errors, s.setTemplateError, s.getData, s.setTemplate, s.setCreator
    ], shallow);
    const questions = useEditorStore(state => state.questions, shallow);

    React.useEffect(() => {
        const { questions } = getData();

        const totalAllottedTime = questions.reduce((acc, q) => acc + (q.allottedTime || 0), 0);
        
        const calculatedTotalTime = (template?.bufferTime || 0) + totalAllottedTime;
        setTotalTime(calculatedTotalTime);
        setTotalTimeError(calculatedTotalTime > 60 ? 'Total time cannot exceed 60 minutes' : '');
    }, [template?.bufferTime, questions]);

    const handleSave = async () => {
        setValidate(true);
        setSave(true);
        setTimeout(() => setValidate(false), 100);

        if (totalTime > 60) {
            setTotalTimeError('Total time cannot exceed 60 minutes');
            return; 
        } else {
            setTotalTimeError('');  
        }

        if (!totalTimeError) {
            try {
                loading.show();

                const { template, questions } = getData();

                const savedTemplate = await saveSEPTemplate(
                    template._id, {
                        ...template,
                        description: template?.description || "", 
                        redirectButtonName : template?.redirectButtonName || "",
                        questions: questions.map((q, index) => ({
                            ...q,
                            serialNumber: index,
                            allottedTime: q.allottedTime * 60,
                        })),
                        sendEmailReport: template?.sendEmailReport || false,
                        bufferTime : template?.bufferTime || 0
                    }
                );

                snackbar.showSnackbar("Template saved successfully", "success");
                
                setTemplate({
                    ...savedTemplate ,
                    description: template.description ,
                    redirectButtonName : template.redirectButtonName,
                    bufferTime : template.bufferTime
                });

                setCreator(true);

                navigate(location.pathname.replace("create", savedTemplate._id));

            } catch (error) {
                console.log(error);
                snackbar.showSnackbar("Uh Oh! Unable to save the template", "error");
            } finally {
                loading.hide();
            }
        }
    };

    React.useEffect(() => {
        if (!validate) return;

        TemplateValidationSchema
            .validate(template, { abortEarly: false })
            .then(values => setTemplateError(null))
            .catch((err) => {
                const errors = err.inner.reduce((acc, error) => ({
                    ...acc, [error.path]: error.message
                }), {});

                setTemplateError(errors);
            });
    }, [validate, template, setTemplateError]);

    React.useEffect(() => {
        if (ref.current) {
            if (!Object.values(errors).find(x => !!x === true)) {
                if (!save) return;

                (async () => {
                    try {
                        loading.show();

                        const { template, questions } = getData();

                        const savedTemplate = await saveSEPTemplate(
                            template._id, {
                                ...template,
                                description: template?.description || "", 
                                redirectButtonName : template?.redirectButtonName || "",
                                questions: questions.map((q, index) => ({
                                    ...q,
                                    serialNumber: index ,
                                    allottedTime: q.allottedTime * 60,
                                })),
                                sendEmailReport: template?.sendEmailReport || false,
                                bufferTime : template?.bufferTime || 0
                            }
                        );

                        snackbar.showSnackbar("Template saved successfully", "success");
                        
                        setTemplate({
                            ...savedTemplate ,
                            description: template.description ,
                            redirectButtonName : template.redirectButtonName,
                            bufferTime : template.bufferTime
                        });

                        setCreator(true);

                        navigate(location.pathname.replace("create", savedTemplate._id));
                    } catch (error) {
                        console.log(error);
                        snackbar.showSnackbar("Uh Oh! Unable to save the template", "error");
                    } finally {
                        loading.hide();
                    }

                })();
                setSave(false);
            } else {
                snackbar.showSnackbar("Please provide valid data!", "error");
            }
        } else {
            ref.current = true;
        }
    }, [save]);

    return (
        <Box display="flex" flexDirection="column" minWidth={250}>
            <SimpleTextField
                label="Template Name*"
                fullWidth
                placeholder="e.g. Product Management Advanced"
                variant="outlined"
                name="name"
                value={template?.name || ""}
                onChange={updateTemplate}
                inputProps={{ readOnly: !creator }}
                error={errors?.template?.name && Boolean(errors?.template?.name)}
                helperText={errors?.template?.name}
            />

            <ClientSelect
                sepClients
                defaultValue={template.user || ""}
                onChange={(client) => updateTemplate({
                    target: { name: 'user', value: client._id }
                })}
                error={errors?.template?.user}
            />

            <SimpleTextField
                label="Description"
                fullWidth
                placeholder="Enter a description"
                variant="outlined"
                name="description"
                multiline
                minRows={4}
                value={template?.description || ""}
                onChange={updateTemplate}
                inputProps={{ readOnly: !creator }}
                error={errors?.template?.description && Boolean(errors?.template?.description)}
                helperText={errors?.template?.description}
            />

            <SimpleTextField
                label="Buffer Time (minutes)"
                fullWidth
                placeholder="Enter allotted time in minutes"
                variant="outlined"
                name="bufferTime"  
                type="number" 
                value={template?.bufferTime || ""}  
                onChange={(e) => updateTemplate({
                    target: { name: 'bufferTime', value: Math.max(0, parseInt(e.target.value) || 0) } 
                })} 
                inputProps={{
                    readOnly: !creator,
                    min: 0, 
                }}
                error={errors?.template?.bufferTime && Boolean(errors?.template?.bufferTime)}
                helperText={errors?.template?.bufferTime}
            />

            <SimpleTextField
                label="Redirect Button Name"
                fullWidth
                placeholder="Redirect button"
                variant="outlined"
                name="redirectButtonName"  
                multiline
                minRows={1}
                value={template?.redirectButtonName || ""}  
                onChange={updateTemplate} 
                inputProps={{ readOnly: !creator }}
                error={errors?.template?.redirectButtonName && Boolean(errors?.template?.redirectButtonName)}
                helperText={errors?.template?.redirectButtonName}
            />

            <CheckboxOptions
                name='module type'
                options={['Enable Proctoring']}
                value={template?.proctorSettings?.generalProctoring ? 'Enable Proctoring' : false}
                onChange={(e) => {
                    updateTemplate({
                        target: { 
                          name: 'proctorSettings', value: {
                            ...template?.proctorSettings,
                            generalProctoring: template?.proctorSettings?.generalProctoring ? false : true
                          }
                        }
                    });
                }}
            />

            <CheckboxOptions
                name='email report'
                options={['Send Report Email']}
                value={template?.sendEmailReport ? 'Send Report Email' : false}
                onChange={(e) => {
                    updateTemplate({
                        target: { 
                            name: 'sendEmailReport', 
                            value: !template?.sendEmailReport 
                        }
                    });
                }}
            />

            <SimpleTextField
                label="Total Time (minutes)"
                fullWidth
                value={totalTime || 0}
                inputProps={{ readOnly: true }}
                error={Boolean(totalTimeError)}
                helperText={totalTimeError}
            />

            <Box display="flex" alignItems={"end"} mt={1} justifyContent="flex-end">
                {creator && <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    disabled={props?.questionsLength === 0 || totalTime > 60}
                    onClick={handleSave}
                >
                    Save Template
                </Button>}
                &nbsp;&nbsp;
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => navigate('/templates/sep')}
                >
                    Back
                </Button>
            </Box>
            <br />
            <AddButton />
        </Box>
    );
}

export default TemplateDetails;
